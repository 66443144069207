
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.team {
  h2 {
    color: #ffffff;
    @media (max-width: map-get($grid-breakpoints, 'sm')) {
      letter-spacing: -0.99px;
    }
  }
  p {
    font-size: 15px;
    font-weight: 300;
    line-height: 120%;
    letter-spacing: -0.72px;
    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      font-size: 18px;
    }
  }
  .boxWrapper {
    margin-right: -45px;
    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      margin-right: 0;
      padding: 0 0;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    }
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      grid-template-columns: repeat(auto-fill, minmax(209px, 1fr));
    }
    .teamBox {
      margin-top: 0x;
      border-radius: 16px;
      @media (min-width: map-get($grid-breakpoints, 'sm')) {
        margin-top: -4px;
      }
      .teamContent {
        background-color: #ffffff;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 10px;
        padding: 20px 10px 0px 20px;
        min-height: 126px;
        margin-top: -16px;
        z-index: 999999;
        position: relative;
      }

      img {
        width: 100%;
        height: 100%;
        max-height: 260px;
        border-top-left-radius: 16px !important;
        border-top-right-radius: 16px !important;
      }
    }
  }
}
