
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.thisMachine {
  .header {
    width: 100vw;
    min-height: 100vh;
    position: relative;
    background-color: #000;
    // max-height: 890px;
    padding: 80px 30px 0;

    .player {
      min-height: 190px;

      @media (min-width: map-get($grid-breakpoints, 'xl')) {
        // min-height: 664px;
      }
    }

    div[class*='player-tm'] {
      height: 100% !important;
    }
    video {
      height: 100% !important;
    }

    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      div[class*='player-tm'] {
        height: calc(100vh - 345px) !important;
      }
      video {
        height: calc(100vh - 345px) !important;
      }
    }
    // @media (min-width: map-get($grid-breakpoints, 'xl')) {
    //   max-height: 970px;
    //   padding: 0 0;
    // }

    // @media (min-width: map-get($grid-breakpoints, 'xl')) {
    //   max-height: 950px;
    //   padding: 0 0;
    // }
    @media (min-width: map-get($grid-breakpoints, 'xxl')) {
      max-height: inherit;
      padding: 0 0;
    }
    h1 {
      line-height: 95%;
      letter-spacing: -3px;
    }

    p {
      font-size: 18px;
      font-weight: 300;
      line-height: 130%;
      letter-spacing: -0.88px;
      @media (min-width: map-get($grid-breakpoints, 'xl')) {
        font-size: 22px;
      }
    }
    .contentWrapper {
      position: relative;
      width: 100%;
      padding-bottom: 120px;

      // @media (min-width: map-get($grid-breakpoints, 'xl')) {
      //   position: absolute;
      //   bottom: 0;
      // }
      // @media all and (max-width: 500px) and (min-width: 360px) {
      //   bottom: 100px;
      // }
      // @media all and (max-width: 700px) and (min-width: 500px) {
      //   bottom: 150px;
      // }
      // @media all and (max-width: 992px) and (min-width: 700px) {
      //   bottom: 240px;
      // }
      // @media all and (max-width: 1201px) and (min-width: 992px) {
      //   bottom: 360px;
      // }
      // @media all and (max-width: 1920px) and (min-width: 1500px) {
      //   bottom: 130px;
      // }
      .content {
        max-width: 560px;
        margin: auto;
        color: #fff !important;
        text-align: center;
        @media (min-width: map-get($grid-breakpoints, 'xl')) {
          max-width: 765px;
        }
        .showUp {
          display: inline-block;
        }
      }
    }
  }

  .text {
    h3 {
      color: #fff;
    }

    h3:not(:last-child) {
      margin-bottom: 30px;
    }
    padding: 30px 40px 70px 40px;

    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      padding: 40px 0 100px 0;
    }
  }
}
