
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.offices {
  background-color: #0c2a31;

  // padding-bottom: 200px;
  @media all and (min-width: 576px) {
    padding-bottom: 100px;
  }
  h2,
  h6,
  p {
    color: #ffffff;
  }
  .sliderContainer {
    position: relative;
    padding-bottom: 0;
    overflow-x: hidden;

    @media all and (min-width: 768px) {
      overflow-x: visible;
      padding-bottom: 430px;
    }
    .sliderWrapper {
      overflow-x: hidden;
      width: 600px;
      @media all and (min-width: 576px) {
        width: 1100px;
      }
      @media all and (min-width: 768px) {
        position: absolute;

        width: 1400px;
      }
      @media all and (min-width: 900px) {
        width: 1700px;
      }
      @media all and (min-width: 1200px) {
        width: 1900px;
      }
      .image {
        img {
          display: none;
        }
        margin-right: 15px !important;
        height: 200px;
        border-radius: 16px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: 70%;
        @media all and (min-width: 576px) {
          img {
            width: 100%;
            height: auto;
            display: block;
          }
          background-image: none !important;
          height: auto;
        }
      }
      .mobileContent {
        padding: 20px 0 20px;
        display: block;
        color: #ffffff;
        cursor: pointer;
        @media all and (min-width: 576px) {
          display: none;
        }
      }
      div[class*='slick-current'] {
        h6 {
          text-decoration: underline;
        }
      }
    }

    div[class='slick-slider'] {
      height: 100%;
      width: 100%;
    }
  }
  .customNav {
    width: 100%;
    display: flex;
    color: #fff;
    display: none;
    @media all and (min-width: 576px) {
      display: flex;
    }
    .navWrapper {
      width: 250px;
      padding: 10px;
      margin: 0 auto;
      cursor: pointer;
      p {
        color: #fff;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: -0.88px;
      }
      h6 {
        color: #fff;
        font-size: 22px;
        font-style: normal;
        font-weight: 800;
        line-height: 140%;
        letter-spacing: -0.88px;
      }
      .trigger {
        padding-bottom: 20px;
        &.activeNav {
          text-decoration: underline;
        }
      }
    }
  }
}
